export enum DepositType {
  Initial = 'Initial',
  Balance = 'Balance',
}

export enum DepositValueType {
  Dollar = 'Dollar',
  Percentage = 'Percentage',
}

export enum ConditionType {
  Contract = 'Contract',
  Unconditional = 'Unconditional',
}

export enum FinancialConditionType {
  OnDate = 'OnDate',
  NdaysFromDate = 'NdaysFromDate',
  OnSpecificDate = 'OnSpecificDate',
  AdditionalReason = 'AdditionalReason',
  UponSatisfactionOfSpecialCondition = 'UponSatisfactionOfSpecialCondition',
}

export interface ErrorState {
  depositError: boolean;
  message: string | null;
}

export interface ContractDepositErrorState {
  initial: ErrorState;
  balance: ErrorState;
}

export interface DepositValue {
  depositValueType: DepositValueType;
  depositType?: DepositType;
  amount?: string;
  percent?: string;
  daysFromDate?: string;
  referenceDate?: string;
  conditionType?: ConditionType;
  condition?: FinancialConditionType;
  specialCondition?: any;
}

export interface ContractDepositValue {
  initialDeposit: DepositValue;
  balanceDeposit: DepositValue;
}

export const DepositOptions = [
  { label: DepositValueType.Dollar, value: DepositValueType.Dollar },
  { label: DepositValueType.Percentage, value: DepositValueType.Percentage },
];

export enum DepositRadioOptionType {
  TenPercentage = '10',
  FivePercentage = '5',
}
