import { AgxSelect } from '@urbanx/agx-ui-components';
import React, { useEffect, useState } from 'react';
import { propertyTypeOptions } from '../SearchNow/constants';

const getInitialValue = defaultValue => {
  return (
    propertyTypeOptions.find(option => option.value === defaultValue) ??
    propertyTypeOptions[0]
  );
};

const PropertyType = ({
  id,
  onValueChanged,
  defaultValue,
  label,
  caption,
  required,
}) => {
  const [propertyType, setPropertyType] = useState(
    getInitialValue(defaultValue)?.value
  );

  useEffect(() => {
    onValueChanged({ id, value: propertyType });
  }, [propertyType]);

  return (
    <AgxSelect
      id={id}
      dataTestId={id}
      options={propertyTypeOptions}
      onValueChanged={({ value }) => setPropertyType(value)}
      defaultValue={getInitialValue(propertyType)}
      required={required}
      label={label}
      caption={caption}
    />
  );
};

export default PropertyType;
