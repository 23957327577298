import { useState } from 'react';
import {
  AgxHeader,
  Images,
  AgxButton,
  AgxRow,
  AgxColumn,
  FormType,
  CampaignStage,
} from '@urbanx/agx-ui-components';
import moment from 'moment';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { beginCampaignForm } from 'Api/Campaigns/campaignsApi';
import { useFormLoader } from 'hooks/useFormLoader';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import SegmentedPicker from '../../../../SegmentedPicker/SegmentedPicker';
import { ReactComponent as HomeLarge } from './Assets/home-outline-large.svg';
import { ReactComponent as EditLarge } from './Assets/edit-2-outline-large.svg';
import { ReactComponent as HomeSmall } from './Assets/home-outline-small.svg';
import { ReactComponent as EditSmall } from './Assets/edit-2-outline-small.svg';
import { ReactComponent as Archived } from 'assets/icons/archive-outline.svg';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { setAndShowErrorToast } from 'store/config';
import FormDetailsHeader from '../Common/FormDetailsHeader';
import ListingDetails from './Common/ListingDetails';
import ContractDetails from './Common/ContractDetails';
import './ListingInfoPanel.scss';
import { CampaignInfoProps } from 'Api/Campaigns/Types/campaignTypes';

const TabType = {
  ListingDetails: 'ListingDetails',
  Contracts: 'Contracts',
};

const isDesktop = ScreenSize() === Breakpoints.Desktop;

const EditIcon = () => (isDesktop ? <EditLarge /> : <EditSmall />);
const HomeIcon = () => (isDesktop ? <HomeLarge /> : <HomeSmall />);

const ListingInfoPanel = ({
  campaign,
  currentTab,
  mobile = false,
}: CampaignInfoProps) => {
  const [selectedTab, setSelectedTab] = useState(TabType.ListingDetails);
  const [disableButtons, setDisableButtons] = useState(false);
  const [, getAuthToken] = useAzureAuth();
  const { loadForm } = useFormLoader();
  const {
    featureToggles: { generateSalesContracts },
  } = useAppSelector(state => state.agentInfo);
  const availableForms = useAppSelector(state => state.forms.availableForms);

  const isArchived = campaign.completionState?.completionType !== null;

  const { address, campaignId, blankContractIsLive, lastUpdated, stage } =
    campaign;

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);

  const segments = [
    {
      title: 'Listing Details',
      isSelected: selectedTab === TabType.ListingDetails,
      onSelected: () => setSelectedTab(TabType.ListingDetails),
    },
    {
      title: 'Contracts',
      isSelected: selectedTab === TabType.Contracts,
      onSelected: () => setSelectedTab(TabType.Contracts),
    },
  ];

  const lastUpdatedOn = `Last updated ${moment(lastUpdated).format(
    'DD/MM/YYYY'
  )}`;

  const beginContract = async () => {
    if (!getAuthToken) return;

    const contractRequestForm = availableForms.find(
      (form: any) => form.type === FormType.ContractRequest
    );

    if (!contractRequestForm) return;

    try {
      setDisableButtons(true);

      const authToken = await getAuthToken();

      if (!authToken) {
        setDisableButtons(false);
        return;
      }

      const newFormId = await beginCampaignForm(
        authToken,
        campaignId,
        contractRequestForm.type,
        contractRequestForm.state
      );

      if (!newFormId) {
        setAndShowErrorToast('Failed to start Contract submission');
        setDisableButtons(false);
        return;
      }

      await loadForm(campaignId, newFormId);
    } catch (err) {
      console.error(err);
    }

    setDisableButtons(false);
  };

  const beginSubmission = async () => {
    if (!getAuthToken) return;

    const contractSubmissionForm = availableForms.find(
      (form: any) => form.type === FormType.ContractSubmission
    );

    if (!contractSubmissionForm) return;

    try {
      setDisableButtons(true);

      const authToken = await getAuthToken();

      if (!authToken) {
        setDisableButtons(false);
        return;
      }

      const newFormId = await beginCampaignForm(
        authToken,
        campaignId,
        contractSubmissionForm.type,
        contractSubmissionForm.state
      );

      if (!newFormId) {
        setAndShowErrorToast('Failed to start Contract submission');
        setDisableButtons(false);
        return;
      }

      await loadForm(campaignId, newFormId);
    } catch (err) {
      console.error(err);
    }

    setDisableButtons(false);
  };

  const iconSelector = () => {
    let icon = <HomeIcon />;
    let iconText = 'Current';

    if (campaign.completionState?.completionType !== null) {
      icon = <Archived />;
      iconText = campaign.completionState?.completionType;
    } else if (stage === CampaignStage.ListingUnderContract) {
      icon = <EditIcon />;
      iconText = stage;
    }
    return (
      <>
        {icon}
        {iconText}
      </>
    );
  };

  return (
    <>
      <AgxColumn extraClasses="listingInfoPanel">
        <FormDetailsHeader
          formName="Listing"
          detail={lastUpdatedOn}
          currentTab={currentTab}
          onBack={undefined}
        />
        <AgxColumn fill centered extraClasses="contractPanelTitle">
          {iconSelector()}
          <AgxHeader size={2} centered>
            {addressLineOne},
            <br />
            {addressLineTwo}
          </AgxHeader>
        </AgxColumn>

        <AgxRow fill justifyCenter>
          <AgxRow extraClasses={'listingSegmentedPickerContainer'}>
            <SegmentedPicker segments={segments} />
          </AgxRow>
        </AgxRow>

        {selectedTab === TabType.ListingDetails && (
          <ListingDetails campaign={campaign} mobile={mobile} />
        )}

        {selectedTab === TabType.Contracts && (
          <ContractDetails
            property={address}
            contractDetails={campaign.contractDetails}
            completionState={campaign.completionState}
            propertyImageUrls={{
              thumbnail: campaign.propertyImageUrls.thumbnail,
            }}
          />
        )}
      </AgxColumn>

      {generateSalesContracts && (
        <div className="campaignModalButtons">
          <AgxButton
            primary
            large
            wide={mobile}
            text="New Contract"
            rightIcon={<Images.FileAddOutline />}
            shrinkOnLargerDevices
            onClick={beginContract}
            disabled={!blankContractIsLive || disableButtons || isArchived}
          />
          <AgxButton
            hollow
            large
            wide={mobile}
            text="Submit Contract"
            rightIcon={<Images.PaperPlaneOutline />}
            shrinkOnLargerDevices
            onClick={beginSubmission}
            disabled={disableButtons || isArchived}
          />
        </div>
      )}
    </>
  );
};

export default ListingInfoPanel;
