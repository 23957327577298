import {
  AustralianState,
  CampaignCompletionStateType,
  CampaignStage,
  PriceRange,
  PropertyAddress,
  PropertyImageUrls,
  UploadedDocument,
  VendorBuyer,
} from '@urbanx/agx-ui-components';
import {
  EnvelopeSubmission,
  GeneratedDocumentModel,
  InProgressFormModel,
} from './types';
import ContractDetailModel from './contractTypes';

export interface CampaignInfoProps {
  campaign: CampaignDetailModel;
  currentTab: string;
  mobile?: boolean;
}

export enum CampaignStageAction {
  Restore = 'Restore',
  RevertToCurrent = 'RevertToCurrent',
  MarkAsUnderContract = 'MarkAsUnderContract',
  MarkAsSold = 'MarkAsSold',
  Archive = 'Archive',
  Withdraw = 'Withdraw',
}

export interface CampaignActionRequest {
  campaignId: string;
  campaignAction: CampaignStageAction;
}

export interface CampaignActionResponse {
  campaignId: string;
  actionSuccessful: boolean;
  completionState: CampaignCompletionState;
}

export interface CampaignDetailModel {
  campaignId: string;
  state: AustralianState;
  stage: CampaignStage;
  blankContractIsLive: boolean;
  address: PropertyAddress;
  propertyId: string;
  read: boolean;
  propertyImageUrls: PropertyImageUrls;
  contractDetails: ContractDetailModel[];
  generatedDocuments: GeneratedDocumentModel[];
  agencyAgreementSubmission: EnvelopeSubmission | null;
  lastUpdated: Date;
  backendPriceRange: PriceRange;
  blankSalesContract: UploadedDocument | null;
  vendors: VendorBuyer[];
  completionState: CampaignCompletionState;
  documents: UploadedDocument[];
  inProgressForms: InProgressFormModel[];
}

export interface CampaignCompletionState {
  completionType: CampaignCompletionStateType | null;
  completionDate: Date | null;
}
