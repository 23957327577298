import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import {
  AgxButton,
  AgxErrorLabel,
  AgxRadio,
  AgxTextInput,
  AgxCurrency,
  AgxCaption,
  AgxLabel,
  Images,
  AgxColumn,
  AgxDivider,
  AgxRow,
} from '@urbanx/agx-ui-components';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { addDividersBetweenElements } from '../../form/formUtils';
import { formatCurrencyWithConditionalDecimals } from 'utils/formatNumber';
import { parseFloatReturnEmptyIfZero } from 'utils/parseFloatReturnEmptyIfZero';
import './TieredCommission.scss';

const TierTypes = {
  SingleRate: 'SingleRate',
  MultiRate: 'MultiRate',
};

const createSingleRateExampleRow = (
  first,
  last,
  lowerBound,
  upperBound,
  percentage
) => {
  if (first) {
    return `${percentage} of the actual sale price for a result achieved up to and including ${upperBound}`;
  } else if (last) {
    return `${percentage} of the actual sale price for any result achieved above ${lowerBound}`;
  } else {
    return `${percentage} of the actual sale price for a result achieved between ${lowerBound} and ${upperBound}`;
  }
};

const createMultiRateExample = (
  first,
  last,
  lowerBound,
  upperBound,
  percentage
) => {
  if (first) {
    return `${percentage} of the actual sale price for a result achieved up to and including ${upperBound}`;
  } else if (last) {
    return `A performance bonus of ${percentage} on any amount achieved over ${lowerBound}`;
  } else {
    return `${percentage} of any amount achieved between ${lowerBound} and ${upperBound} inclusive`;
  }
};

const tierSystemOptions = [
  {
    description: 'Single rate',
    value: TierTypes.SingleRate,
  },
  {
    description: 'Multi-rate',
    value: TierTypes.MultiRate,
  },
];

const TieredCommission = ({ id, onValueChanged, defaultValue, validate }) => {
  const [tierType, setTierType] = useState(
    defaultValue?.TieredSystemType ?? TierTypes.SingleRate
  );
  const [tieredRows, setTieredRows] = useState(
    defaultValue?.Rows ?? [
      {
        id: uuidv4(),
        LowerBound: 0.0,
        UpperBound: 0.0,
        PercentageWithinBound: 0.0,
      },
      {
        id: uuidv4(),
        LowerBound: 0.0,
        UpperBound: 0.0,
        PercentageWithinBound: 0.0,
      },
    ]
  );
  const { formType, location } = useSelector(state => state.form.selectedForm);
  const availableForms = useSelector(state => state.forms.availableForms);
  const formName = availableForms.find(
    form => form.type === formType && isEqual(form.location, location)
  )?.name;

  const [error, setError] = useState({});

  useEffect(() => {
    validateRows();
  }, [tieredRows]);

  useEffect(() => {
    if (Object.keys(error).length > 0) return;

    onValueChanged({
      id,
      value: {
        Rows: tieredRows.map((row, rowNumber) => {
          return {
            ...row,
            LowerBound: row.LowerBound === '' ? 0.0 : row.LowerBound,
            UpperBound: row.UpperBound === '' ? 0.0 : row.UpperBound,
            PercentageWithinBound:
              row.PercentageWithinBound === ''
                ? 0.0
                : row.PercentageWithinBound,
            order: rowNumber,
          };
        }),
        TieredSystemType: tierType,
      },
    });
  }, [tieredRows, tierType, error]);

  const updateRowUpperBound = useCallback(
    (rowNumber, value) => {
      const updatedRows = [...tieredRows];

      updatedRows[rowNumber] = {
        ...updatedRows[rowNumber],
        UpperBound: value,
      };

      if (updatedRows.length >= rowNumber + 2) {
        updatedRows[rowNumber + 1] = {
          ...updatedRows[rowNumber + 1],
          LowerBound: value ?? 0.0,
          UpperBound: updatedRows[rowNumber + 1].UpperBound,
        };
      }

      setTieredRows(updatedRows);
    },
    [tieredRows, setTieredRows]
  );

  const updateRowPercentage = useCallback(
    (rowNumber, value) => {
      const updatedRows = [...tieredRows];

      updatedRows[rowNumber] = {
        ...updatedRows[rowNumber],
        PercentageWithinBound: value ?? 0.0,
      };

      setTieredRows(updatedRows);
    },
    [tieredRows, setTieredRows]
  );

  const addRow = useCallback(() => {
    const previousTier = tieredRows[tieredRows.length - 1];

    const newTier = {
      id: uuidv4(),
      LowerBound:
        tieredRows.length === 1 ? Number(previousTier.UpperBound) : 0.0,
      UpperBound: null,
      PercentageWithinBound: 0.0,
    };

    setTieredRows([...tieredRows, newTier]);
  }, [tieredRows, setTieredRows]);

  const validateRows = useCallback(() => {
    let error = {};
    tieredRows.map((row, i) => {
      if (i > 0) {
        if (row.UpperBound && Number(row.UpperBound) <= row.LowerBound) {
          error = {
            ...error,
            [row.id]: `Value must be greater than ${row.LowerBound}`,
          };
        }
      }
      return null;
    });
    setError(error);
  }, [tieredRows, setError]);

  const deleteRow = useCallback(
    rowNumber => {
      let rows = [...tieredRows];
      rows.splice(rowNumber, 1);

      // Reset the upper bound of  the last row to be unbounded.
      rows.splice(rowNumber - 1, 1, {
        ...rows[rowNumber - 1],
        UpperBound: null,
      });

      setTieredRows(rows);
    },
    [tieredRows, setTieredRows]
  );

  const example = useMemo(() => {
    if (tierType === null) {
      return null;
    }

    if (!tieredRows || tieredRows?.length < 1) {
      return null;
    }

    let tierRowsText = [];
    for (var i = 0; i < tieredRows.length; i++) {
      const { LowerBound, UpperBound, PercentageWithinBound } = tieredRows[i];

      const first = i === 0;
      const last = i === tieredRows.length - 1;

      const lowerBound =
        LowerBound && LowerBound > 0
          ? formatCurrencyWithConditionalDecimals(Number(LowerBound) + 1)
          : '$0.00';
      const upperBound =
        UpperBound && UpperBound > 0
          ? formatCurrencyWithConditionalDecimals(UpperBound)
          : '$0.00';
      const percentage =
        PercentageWithinBound && PercentageWithinBound > 0
          ? `${Number(PercentageWithinBound)
              .toFixed(2)
              .replace(/\.?0+$/, '')}%`
          : '0%';

      if (tierType === TierTypes.SingleRate) {
        tierRowsText.push(
          createSingleRateExampleRow(
            first,
            last,
            lowerBound,
            upperBound,
            percentage
          )
        );
      } else if (tierType === TierTypes.MultiRate) {
        tierRowsText.push(
          createMultiRateExample(
            first,
            last,
            lowerBound,
            upperBound,
            percentage
          )
        );
      }
    }

    return tierRowsText;
  }, [tieredRows, tierType]);

  const renderCommissionRows = () => {
    return addDividersBetweenElements(
      tieredRows?.map((row, rowNumber) => {
        const {
          id: rowId,
          LowerBound,
          UpperBound,
          PercentageWithinBound,
        } = row;

        const isAndAboveRow =
          rowNumber > 0 && rowNumber === tieredRows.length - 1;

        const tierValueClasses = clsx(
          'tierValueInputs',
          isAndAboveRow && 'isAndAboveRow'
        );

        const leftLabel =
          rowNumber === 0 ? (
            <AgxLabel extraClasses="commissionLowerBound">
              Up to and including
            </AgxLabel>
          ) : (
            <AgxLabel extraClasses="commissionLowerBound">
              {formatCurrencyWithConditionalDecimals(Number(LowerBound) + 1)}{' '}
              {rowNumber < tieredRows.length - 1 ? 'to' : 'or more'}
            </AgxLabel>
          );

        return (
          <AgxColumn key={rowId} fill mediumGap>
            <AgxColumn fill mediumGap>
              <div className={tierValueClasses}>
                {leftLabel}
                {!isAndAboveRow && (
                  <AgxCurrency
                    id="UpperBound"
                    key={rowNumber}
                    noHeader
                    onInputValueChange={({ value }) =>
                      updateRowUpperBound(rowNumber, value)
                    }
                    defaultValue={parseFloatReturnEmptyIfZero(UpperBound)}
                    stretch
                    extraClasses="commissionUpperBound"
                    displayErrors={
                      validate && !parseFloatReturnEmptyIfZero(UpperBound)
                    }
                    validate={validate}
                  />
                )}
                <AgxLabel extraClasses="commissionAt">at</AgxLabel>
                <AgxTextInput
                  id="PercentageWithinBound"
                  noHeader
                  noOptionalLabel
                  percent
                  onInputValueChange={({ value }) => {
                    updateRowPercentage(rowNumber, value);
                  }}
                  defaultValue={parseFloatReturnEmptyIfZero(
                    PercentageWithinBound
                  )}
                  error={
                    validate &&
                    !parseFloatReturnEmptyIfZero(PercentageWithinBound)
                  }
                  hideErrorDescription
                  extraClasses="commissionPercentage"
                />
                {rowNumber > 1 && (
                  <AgxRow
                    centered
                    justifyCenter
                    extraClasses="commissionRemove"
                  >
                    <AgxButton
                      minimal
                      rightIcon={<Images.CrossFill />}
                      onClick={() => deleteRow(rowNumber)}
                    />
                  </AgxRow>
                )}
              </div>
            </AgxColumn>
            {error[rowId] && <AgxErrorLabel error={error[rowId]} />}
          </AgxColumn>
        );
      })
    );
  };

  return (
    <>
      <AgxColumn largeGap fill extraClasses="commissionStructure">
        <AgxLabel medium>Commission Structure</AgxLabel>
        <AgxColumn largeGap fill>
          <AgxDivider />
          {renderCommissionRows()}
        </AgxColumn>
        <AgxDivider />
        <AgxButton
          minimal
          text="ADD ROW"
          rightIcon={<Images.PlusCircleBlack />}
          onClick={addRow}
        />
        <AgxDivider />
      </AgxColumn>
      <AgxColumn mediumGap fill>
        <div className="agxRadio">
          <AgxLabel medium>Tier System</AgxLabel>
          <div className="agxRadio-group">
            {tierSystemOptions.map(({ description, value }) => (
              <AgxRadio
                key={value}
                id={value}
                label={description}
                onCheckChanged={() => setTierType(value)}
                checked={value === tierType}
              />
            ))}
          </div>
        </div>
        {example != null && (
          <AgxColumn smallGap>
            <AgxCaption>{`This will appear on the ${formName} as:`}</AgxCaption>
            <br />
            {example.map((tr, index) => {
              return <AgxCaption key={`caption-${index}`}>{tr}</AgxCaption>;
            })}
          </AgxColumn>
        )}
      </AgxColumn>
    </>
  );
};

export default TieredCommission;
