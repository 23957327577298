import React, { useState, useEffect, useMemo } from 'react';
import {
  AgxColumn,
  AgxLabel,
  AgxRadio,
  AgxCheckbox,
  AgxSelect,
  Images,
  AgxBodyText,
} from '@urbanx/agx-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { fetchListingAgents } from '../../listingAgent/listingAgentReducer';
import { LoadingState } from 'utils/loadingState';
import './SendForDocuSigning.scss';
import isEqual from 'lodash.isequal';

// Mapping to SendForDocuSigningType enum
const SendForDocuSigningType = {
  SendForDocuSigning: 1,
  EmailToAgent: 2,
};

const constructAgencyContact = userAccount => {
  return {
    name: {
      firstName: userAccount?.firstName,
      lastName: userAccount?.lastName,
    },
    email: {
      address: userAccount?.agent,
    },
  };
};

const SendForDocuSigning = ({
  id,
  label,
  onValueChanged,
  defaultValue,
  validate,
}) => {
  const [userAccount, getAuthToken] = useAzureAuth();
  const [localError, setLocalError] = useState('');
  const { agents, loadingState: agentsLoadingState } = useSelector(
    state => state.listingAgent
  );

  const [sendForDocuSigningState, setSendForDocuSigningState] = useState({
    condition:
      (defaultValue?.agencyContact && SendForDocuSigningType.EmailToAgent) ??
      (defaultValue?.hasOwnProperty('sendToBuyers') &&
        SendForDocuSigningType.SendForDocuSigning),

    sendToBuyers: defaultValue?.sendToBuyers ?? false,
    sendToVendors: defaultValue?.sendToVendors ?? false,
    transferDocuSignOwnershipToAgent:
      defaultValue?.transferDocuSignOwnershipToAgent ?? false,
    agencyContact: constructAgencyContact(userAccount),
    agentEmail: {
      address: defaultValue?.agentEmail?.address ?? '',
    },
  });

  const {
    condition,
    sendToBuyers,
    sendToVendors,
    transferDocuSignOwnershipToAgent,
    agencyContact,
    agentEmail,
  } = sendForDocuSigningState;

  useEffect(() => {
    const updatedAgencyContact = constructAgencyContact(userAccount);

    if (isEqual(updatedAgencyContact, sendForDocuSigningState.agencyContact))
      return;

    setSendForDocuSigningState({
      ...sendForDocuSigningState,
      agencyContact: updatedAgencyContact,
    });
  }, [userAccount, setSendForDocuSigningState, sendForDocuSigningState]);

  const validatePage = state => {
    if (validate) {
      if (!state.condition) {
        setLocalError('Select an option');
        return;
      }

      if (
        state.condition === SendForDocuSigningType.SendForDocuSigning &&
        !state.sendToBuyers &&
        !state.sendToVendors
      ) {
        setLocalError('Select at least one option');
        return;
      }

      setLocalError('');
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (agentsLoadingState !== LoadingState.NotLoaded) return;

    getAuthToken().then(token => {
      dispatch(fetchListingAgents(token));
    });
  }, [agentsLoadingState]);

  const agentOptions = [
    {
      value: '',
      label: 'Please select an agent',
    },
  ].concat(
    agents.map(agent => {
      return {
        value: agent.id,
        label: `${agent.name?.firstName} ${agent.name?.lastName}`,
      };
    })
  );

  useEffect(() => {
    condition === SendForDocuSigningType.SendForDocuSigning &&
    (sendToBuyers || sendToVendors || transferDocuSignOwnershipToAgent)
      ? onValueChanged({
          id,
          value: {
            agencyContact,
            sendToBuyers,
            sendToVendors,
            transferDocuSignOwnershipToAgent,
            condition,
          },
        })
      : onValueChanged({
          id,
          value: {
            agencyContact,
            condition,
            agentEmail,
          },
        });
  }, [sendForDocuSigningState]);

  useEffect(() => {
    validatePage(sendForDocuSigningState);
  }, [validate]);

  const selectDefault = useMemo(() => {
    return agentOptions.find(opt => opt.value === agentEmail.address);
  }, [agentEmail, agentOptions]);

  return (
    <div className="agxRadio">
      <AgxLabel medium>{label}</AgxLabel>
      <div className="agxRadio-group">
        <AgxRadio
          id="sendForDocuSigning"
          label="Send for DocuSigning"
          onCheckChanged={() => {
            setSendForDocuSigningState(prev => ({
              ...prev,
              condition: SendForDocuSigningType.SendForDocuSigning,
            }));
            validatePage({
              ...sendForDocuSigningState,
              condition: SendForDocuSigningType.SendForDocuSigning,
            });
          }}
          checked={condition === SendForDocuSigningType.SendForDocuSigning}
          subContent={
            condition === SendForDocuSigningType.SendForDocuSigning && [
              <AgxColumn largeGap fill key="sendForContent">
                <AgxColumn mediumGap>
                  <AgxLabel medium>Send the contract to:</AgxLabel>
                  <AgxCheckbox
                    id="sendTheContractTo_Buyers"
                    defaultValue={sendToBuyers}
                    label="Send to Buyers"
                    onValueChanged={({ value }) => {
                      setSendForDocuSigningState(prev => ({
                        ...prev,
                        sendToBuyers: value,
                      }));
                      validatePage({
                        ...sendForDocuSigningState,
                        sendToBuyers: value,
                      });
                    }}
                  />
                  <AgxCheckbox
                    id="sendTheContractTo_vendors"
                    defaultValue={sendToVendors}
                    label="Send to Vendors"
                    onValueChanged={({ value }) => {
                      setSendForDocuSigningState(prev => ({
                        ...prev,
                        sendToVendors: value,
                      }));
                      validatePage({
                        ...sendForDocuSigningState,
                        sendToVendors: value,
                      });
                    }}
                  />
                  {localError && validate && (
                    <div className={`errorMessage`}>
                      <Images.AlertCircle />
                      <AgxBodyText small extraClasses={'error'}>
                        {localError}
                      </AgxBodyText>
                    </div>
                  )}
                </AgxColumn>
                <AgxColumn mediumGap>
                  <AgxLabel medium>
                    Do you want ownership of the DocuSign Envelope transferred
                    to you?
                  </AgxLabel>
                  <AgxCheckbox
                    id="transferOwnershipToMe"
                    defaultValue={transferDocuSignOwnershipToAgent}
                    label="Yes, transfer ownership to me"
                    onValueChanged={({ value }) =>
                      setSendForDocuSigningState(prev => ({
                        ...prev,
                        transferDocuSignOwnershipToAgent: value,
                      }))
                    }
                  />
                </AgxColumn>
              </AgxColumn>,
            ]
          }
        />
        <AgxRadio
          id="emailToAgent"
          label="Email to agent"
          email
          onCheckChanged={() => {
            setSendForDocuSigningState(prev => ({
              ...prev,
              condition: SendForDocuSigningType.EmailToAgent,
              agentEmail: {
                address: userAccount?.agent,
              },
            }));
            validatePage({
              ...sendForDocuSigningState,
              condition: SendForDocuSigningType.EmailToAgent,
            });
          }}
          checked={condition === SendForDocuSigningType.EmailToAgent}
          subContent={
            condition === SendForDocuSigningType.EmailToAgent && [
              <AgxSelect
                id={id}
                key={id}
                options={agentOptions}
                label="Agent to email contract to:"
                onValueChanged={({ value }) => {
                  setSendForDocuSigningState(prev => ({
                    ...prev,
                    agentEmail: {
                      address: value,
                    },
                  }));
                }}
                defaultValue={selectDefault}
                required
              />,
            ]
          }
        />
      </div>
      {localError && validate && !sendForDocuSigningState.condition && (
        <div className={`errorMessage`}>
          <Images.AlertCircle />
          <AgxBodyText small extraClasses={'error'}>
            {localError}
          </AgxBodyText>
        </div>
      )}
    </div>
  );
};

export default SendForDocuSigning;
