import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AgxCaption,
  AgxColours,
  AgxColumn,
  AgxRow,
  AgxButton,
  Images,
  CampaignStage,
} from '@urbanx/agx-ui-components';
import { CampaignStageAction } from 'Api/Campaigns/Types/campaignTypes';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { performCampaignAction } from 'features/campaigns/campaignsReducer';
import { Tabs } from 'types/Tabs';
import { useSelectedCampaign } from 'hooks/useSelectedCampaign';
import './FormDetailsHeader.scss';

const FormDetailsHeader = ({
  formName,
  detail,
  currentTab,
  onBack = () => {},
}) => {
  const {
    campaignId,
    stage: campaignStage,
    stageStatus,
  } = useSelectedCampaign();

  const [disableArchiveButton, setDisableArchiveButton] = useState(false);
  const dispatch = useDispatch();
  const [, getAuthToken] = useAzureAuth();

  const title = (
    <div
      className={`ui-strong-small ${AgxColours.NEUTRAL_GREY_600} formDetailsTitle`}
    >
      {formName}
    </div>
  );

  const backButton = !!onBack && (
    <AgxRow
      centered
      justifyCenter
      extraClasses="formDetailsBack"
      onClick={onBack}
    >
      <Images.ArrowBackOutline />
    </AgxRow>
  );

  const detailSection = (
    <AgxCaption colour={AgxColours.NEUTRAL_GREY_600}>{detail}</AgxCaption>
  );

  const isCampaignStageValid =
    campaignStage === CampaignStage.ListingGenerator &&
    Object.keys(CampaignStage).includes(stageStatus);

  const shouldRenderRestore =
    isCampaignStageValid && currentTab === Tabs.Archive;
  const shouldRenderArchive =
    isCampaignStageValid &&
    currentTab !== Tabs.Archive &&
    currentTab !== Tabs.Listings;

  const performAction = async action => {
    const authToken = await getAuthToken();
    const result = dispatch(
      performCampaignAction(authToken, { campaignId, campaignAction: action })
    );

    if (action === CampaignStageAction.Archive && result !== 'success') {
      setDisableArchiveButton(false);
    }
  };

  const onArchiveCampaign = () => {
    setDisableArchiveButton(true);
    performAction(CampaignStageAction.Archive);
  };

  const onRestoreCampaign = () => {
    performAction(CampaignStageAction.Restore);
  };

  return (
    <>
      <AgxRow spaceBetween fill extraClasses="formDetailsHeaderMobile" centered>
        <div>
          {shouldRenderRestore && (
            <div className="restore" onClick={onRestoreCampaign}>
              <Images.RestoreOutline />
            </div>
          )}
          {shouldRenderArchive && !disableArchiveButton && (
            <div className="archive" onClick={onArchiveCampaign}>
              <Images.ArchiveOutline />
            </div>
          )}
        </div>
        {backButton}
        <AgxColumn
          centerJustified
          centered
          extraClasses="formDetailsTitleLastUpdated"
        >
          {title}
          {detailSection}
        </AgxColumn>
      </AgxRow>
      <AgxRow
        spaceBetween
        fill
        extraClasses="formDetailsHeaderDesktop"
        centered
      >
        <AgxRow
          smallGap
          centered
          extraClasses="formDetailsTitleInfo"
          onClick={onBack}
        >
          {backButton}
          {title}
        </AgxRow>
        {detailSection}
        {shouldRenderRestore && (
          <AgxButton
            leftIcon={<Images.RestoreOutline />}
            naked
            text="Restore"
            extraClasses="formDetailsArchiveButton"
            onClick={onRestoreCampaign}
          />
        )}
        {shouldRenderArchive && (
          <AgxButton
            leftIcon={<Images.ArchiveOutline />}
            naked
            text="Archive"
            disabled={disableArchiveButton}
            extraClasses="formDetailsArchiveButton"
            onClick={onArchiveCampaign}
          />
        )}
      </AgxRow>
    </>
  );
};

export default FormDetailsHeader;
