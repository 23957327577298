import {
  AgxCaption,
  AgxColumn,
  AgxHeader,
  AgxRow,
  QldContractStage,
  NswContractStage,
  AustralianState,
} from '@urbanx/agx-ui-components';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { selectContract } from '../../../../../../campaigns/campaignsReducer';
import { formatCurrency } from 'utils/formatNumber';
import { getMomentFromNow } from 'utils/utcDateFromNow';
import './ContractCard.scss';
import clsx from 'clsx';
import ContractDetailModel from 'Api/Campaigns/Types/contractTypes';
import { getEnumValue } from 'helpers/enumHelper';

interface ContractCardProps {
  state: string;
  contract: ContractDetailModel & { formType?: string };
  propertyImage: string;
  isArchived: boolean;
}

const ContractCard = ({
  state,
  contract,
  propertyImage,
  isArchived,
}: ContractCardProps) => {
  const dispatch = useAppDispatch();
  const {
    lastUpdate,
    buyers = [],
    purchasePrice,
    contractId,
    contractStage,
  } = contract;

  const buyerNames =
    buyers.length === 0
      ? 'No Name'
      : buyers
          .map(buyer => {
            if (buyer.individual)
              return `${buyer.individual.name?.firstName} ${buyer.individual.name?.lastName}`;
            if (buyer.company) return buyer.company.companyName;
            if (buyer.powerOfAttorney)
              return `${buyer.powerOfAttorney.principalName?.firstName} ${buyer.powerOfAttorney.principalName?.lastName}`;

            return '';
          })
          .join(', ');

  const imageClasses = clsx('propertyImage', isArchived && 'archived');

  const stageTypes: { [key: string]: object } = {
    [AustralianState.NSW]: NswContractStage,
    [AustralianState.QLD]: QldContractStage,
  };

  return (
    <AgxRow
      fill
      extraClasses="contractCard"
      justifyCenter
      largeGap
      centered
      onClick={() => dispatch(selectContract(contractId))}
    >
      <img className={imageClasses} src={propertyImage} alt="Property" />
      <AgxColumn fill>
        <AgxRow fill spaceBetween centered>
          <AgxCaption small>
            {getEnumValue(stageTypes[state], contractStage)}
          </AgxCaption>
          <AgxCaption>{getMomentFromNow(lastUpdate)}</AgxCaption>
        </AgxRow>
        <AgxHeader size={4}>{buyerNames}</AgxHeader>
        <AgxHeader size={4}>
          {purchasePrice == null ? `$-- --` : formatCurrency(purchasePrice, 0)}
        </AgxHeader>
      </AgxColumn>
    </AgxRow>
  );
};

export default ContractCard;
