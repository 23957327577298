import {
  AgxCheckbox,
  AgxLabel,
  AgxRadio,
  AustralianState,
} from '@urbanx/agx-ui-components';
import React, { useEffect, useState } from 'react';
import AuctionDetails from '../AuctionDetails/AuctionDetails';

export const SaleType = {
  PrivateTreaty: 'PrivateTreaty',
  Auction: 'Auction',
  OffMarket: 'OffMarket',
};

const SaleDetails = ({
  id,
  validate,
  propertyAddress,
  onValueChanged,
  defaultValue,
}) => {
  const [saleDetailsState, setSaleDetailsState] = useState({
    saleType: defaultValue?.saleType,
    auctionDate: defaultValue?.auctionDate,
    auctionLocation: defaultValue?.auctionLocation,
    addAuctionDetails: defaultValue?.auctionDate != null,
  });

  const { saleType, auctionDate, auctionLocation, addAuctionDetails } =
    saleDetailsState;

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        ...saleDetailsState,
        auctionDate: addAuctionDetails ? auctionDate : null,
        auctionLocation: addAuctionDetails ? auctionLocation : null,
      },
    });
  }, [saleDetailsState]);

  const isNSW = propertyAddress?.state === AustralianState[AustralianState.NSW];

  return (
    <div className="agxRadio">
      <AgxLabel medium>Sale Type</AgxLabel>
      <div className="agxRadio-group">
        <AgxRadio
          id="privateTreaty"
          label="Private Treaty"
          onCheckChanged={() =>
            setSaleDetailsState({
              ...saleDetailsState,
              saleType: SaleType.PrivateTreaty,
            })
          }
          checked={saleType === SaleType.PrivateTreaty}
        />
        <AgxRadio
          id="auction"
          label="Auction"
          onCheckChanged={() =>
            setSaleDetailsState({
              ...saleDetailsState,
              saleType: SaleType.Auction,
            })
          }
          checked={saleType === SaleType.Auction}
          subContent={
            saleType === SaleType.Auction && [
              <React.Fragment key="addAuctionDetails">
                <AgxCheckbox
                  id="addAuctionDetails"
                  onValueChanged={({ value }) =>
                    setSaleDetailsState({
                      ...saleDetailsState,
                      addAuctionDetails: value,
                    })
                  }
                  defaultValue={addAuctionDetails}
                  label={
                    isNSW ? 'Add auction details' : 'Add Auction Date and Time'
                  }
                  stretch
                />
              </React.Fragment>,
              <React.Fragment key="details">
                {addAuctionDetails && (
                  <AuctionDetails
                    id="auctionDetails"
                    validate={validate}
                    required={isNSW ? false : true}
                    onValueChanged={({ value: { auctionDate, location } }) => {
                      setSaleDetailsState({
                        ...saleDetailsState,
                        auctionDate: auctionDate,
                        auctionLocation: location,
                      });
                    }}
                    defaultValue={{
                      auctionDate,
                      location: auctionLocation,
                    }}
                  />
                )}
              </React.Fragment>,
            ]
          }
        />
        {!isNSW && (
          <AgxRadio
            id="offMarket"
            label="Off-market"
            onCheckChanged={() =>
              setSaleDetailsState({
                ...saleDetailsState,
                saleType: SaleType.OffMarket,
              })
            }
            checked={saleType === SaleType.OffMarket}
          />
        )}
      </div>
    </div>
  );
};

export default SaleDetails;
