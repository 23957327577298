import React, { useState, useEffect } from 'react';
import {
  AgxRow,
  AgxLabel,
  AgxRadio,
  AgxTextInput,
  AgxDatePicker,
  AgxCaption,
  AgxColumn,
  AgxColours,
  Images,
  AgxBodyText,
} from '@urbanx/agx-ui-components';
import './DepositPayable.scss';
import SpecialClause from '../SpecialClause/SpecialClause';
import Deposit, { DefaultErrorState } from './Deposit';
import {
  FinancialConditionType,
  DepositType,
  DepositOptions,
  ConditionType,
  ErrorState,
} from './types';

interface DepositPayableProps {
  id: string;
  label: string;
  onValueChanged: (value: any) => void;
  defaultValue: any;
  required?: boolean;
  validate?: boolean;
  error?: ErrorState;
}

const DepositPayable: React.FC<DepositPayableProps> = ({
  id,
  label,
  onValueChanged,
  defaultValue,
  required = false,
  validate = false,
  error = DefaultErrorState,
}) => {
  const [depositValue, setDepositValue] = useState({
    depositType: defaultValue?.depositType,
    depositValueType: defaultValue?.depositValueType ?? DepositOptions[0].value,
    amount: defaultValue?.amount,
    percent: defaultValue?.percent,
    daysFromDate: defaultValue?.daysFromDate,
    referenceDate: defaultValue?.referenceDate,
    conditionType: defaultValue?.conditionType,
    condition: defaultValue?.condition,
    customSpecialCondition: defaultValue?.customSpecialCondition,
  });

  const conditionTypeCheck =
    id === DepositType.Initial
      ? ConditionType.Contract
      : ConditionType.Unconditional;

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        ...depositValue,
        depositType: id,
        conditionType: conditionTypeCheck,
      },
    });
  }, [depositValue]);

  const isInitialDeposit = depositValue.depositType === DepositType.Initial;
  const hasAmountOrPercent =
    depositValue.amount !== undefined || depositValue.percent !== undefined;
  const isConditionUnchecked = !depositValue.condition;

  const showDepositPayableValidation = () => {
    if (isInitialDeposit) {
      return validate && isConditionUnchecked && required;
    } else {
      return hasAmountOrPercent ? validate && isConditionUnchecked : false;
    }
  };

  const showBalanceDepositValidation = () => {
    return !isInitialDeposit && !isConditionUnchecked && !hasAmountOrPercent;
  };

  return (
    <AgxColumn veryLargeGap>
      <Deposit
        key={id}
        id={id}
        label={label}
        onValueChanged={({ value }) => {
          setDepositValue({
            ...depositValue,
            depositValueType: value.depositValueType,
            amount: value.amount,
            percent: value.percent,
          });
        }}
        defaultValue={depositValue}
        required={required}
        validate={validate}
        balanceDepositValidation={showBalanceDepositValidation()}
        error={error}
        decimalPoints={0}
      />
      <AgxColumn mediumGap fill>
        <AgxRow fill spaceBetween>
          <AgxLabel medium>{label} Payable</AgxLabel>
          {!required && (
            <AgxCaption colour={AgxColours.NEUTRAL_GREY_600}>
              optional
            </AgxCaption>
          )}
        </AgxRow>
        <div className="agxRadio-group">
          <AgxRadio
            id={`on${conditionTypeCheck}`}
            label={`On ${conditionTypeCheck} Date`}
            onCheckChanged={() =>
              setDepositValue({
                ...depositValue,
                condition: FinancialConditionType.OnDate,
              })
            }
            checked={depositValue.condition === FinancialConditionType.OnDate}
          />
          <AgxRadio
            id={`${conditionTypeCheck}Days`}
            label={`__day(s) from ${conditionTypeCheck} Date`}
            onCheckChanged={() =>
              setDepositValue({
                ...depositValue,
                condition: FinancialConditionType.NdaysFromDate,
              })
            }
            checked={
              depositValue.condition === FinancialConditionType.NdaysFromDate
            }
            subContent={
              depositValue.condition === FinancialConditionType.NdaysFromDate
                ? [
                    <AgxRow centered mediumGap>
                      <AgxTextInput
                        id="numberOfDays"
                        noHeader
                        numberOnly
                        noOptionalLabel
                        small
                        maxLength={2}
                        defaultValue={depositValue.daysFromDate}
                        extraClasses="depositPayableTextInput"
                        onInputValueChange={({ value }) =>
                          setDepositValue({
                            ...depositValue,
                            daysFromDate: value,
                          })
                        }
                        rightIcon={<AgxLabel small>day(s)</AgxLabel>}
                        error={
                          validate &&
                          (depositValue.daysFromDate === '' ||
                            (hasAmountOrPercent &&
                              depositValue.daysFromDate === undefined))
                            ? 'Enter days'
                            : undefined
                        }
                      />
                      <AgxLabel small>from {conditionTypeCheck} Date</AgxLabel>
                    </AgxRow>,
                  ]
                : undefined
            }
          />
          <AgxRadio
            id={`${conditionTypeCheck}Date`}
            label="On Specific Date"
            onCheckChanged={() =>
              setDepositValue({
                ...depositValue,
                condition: FinancialConditionType.OnSpecificDate,
              })
            }
            checked={
              depositValue.condition === FinancialConditionType.OnSpecificDate
            }
            subContent={
              depositValue.condition === FinancialConditionType.OnSpecificDate
                ? [
                    <AgxDatePicker
                      key={`${conditionTypeCheck}OnSpecificDate`}
                      id={`${conditionTypeCheck}OnSpecificDate`}
                      onValueChanged={({ value }) =>
                        setDepositValue({
                          ...depositValue,
                          referenceDate: value,
                        })
                      }
                      required
                      noHeader
                      noOptionalLabel
                      defaultValue={depositValue.referenceDate}
                      date
                      stretch
                      error={
                        validate && depositValue.referenceDate === ''
                          ? 'Enter a date'
                          : undefined
                      }
                    />,
                  ]
                : undefined
            }
          />
          <AgxRadio
            id={`${conditionTypeCheck}SpecialCondition`}
            label="Upon satisfaction of Special Condition"
            onCheckChanged={() =>
              setDepositValue({
                ...depositValue,
                condition:
                  FinancialConditionType.UponSatisfactionOfSpecialCondition,
              })
            }
            checked={
              depositValue.condition ===
              FinancialConditionType.UponSatisfactionOfSpecialCondition
            }
            subContent={
              depositValue.condition ===
              FinancialConditionType.UponSatisfactionOfSpecialCondition
                ? [
                    <SpecialClause
                      key={`${conditionTypeCheck}SpecialClause`}
                      id={`${conditionTypeCheck}SpecialClause`}
                      validate={validate}
                      defaultValue={depositValue.customSpecialCondition}
                      onSelectedClause={(value: any) =>
                        setDepositValue({
                          ...depositValue,
                          customSpecialCondition: value,
                        })
                      }
                    />,
                  ]
                : undefined
            }
          />
        </div>
      </AgxColumn>
      {showDepositPayableValidation() && (
        <div className={'errorMessage'}>
          <Images.AlertCircle />
          <AgxBodyText small extraClasses={'error'}>
            {'Select an option'}
          </AgxBodyText>
        </div>
      )}
    </AgxColumn>
  );
};

export default DepositPayable;
