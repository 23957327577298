import { CampaignDetailModel } from 'Api/Campaigns/Types/campaignTypes';
import ResumeFormDetails from '../Common/ResumeFormDetails';

const AgencyAgreementSubmissionInfoPanel = ({
  campaign,
  currentTab,
  mobile = false,
}: {
  campaign: CampaignDetailModel;
  currentTab: string;
  mobile?: boolean;
}) => (
  <ResumeFormDetails
    campaign={campaign}
    mobile={mobile}
    currentTab={currentTab}
  />
);

export default AgencyAgreementSubmissionInfoPanel;
