import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AgxButton, Images } from '@urbanx/agx-ui-components';
import { setSelectedForm } from 'features/form/formReducer';
import { useFormClearer } from 'hooks/useFormCleaner';

export const NewFormButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clearFormData = useFormClearer();
  const { availableForms } = useSelector(state => state.forms);

  const form = useMemo(
    () => availableForms.find(af => af.beginsCampaign) ?? null,
    [availableForms]
  );

  if (!form) return null;

  const onFormButtonClicked = (formType, state) => {
    clearFormData();
    dispatch(setSelectedForm({ formType, state: state }));
    navigate('/form');
  };

  return (
    <AgxButton
      key={form.type}
      text={`New ${form.name}`}
      onClick={() => onFormButtonClicked(form.type, form.state)}
      primary
      large
      dataTestId={'agx-btn-newform6'}
      rightIcon={<Images.Plus />}
    />
  );
};
