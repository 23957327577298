import { createNumberArray } from 'utils/numbers';

export const propertyTypeOptions = [
  { label: 'House', value: 'House' },
  { label: 'Unit/Apartment', value: 'Unit/Apartment' },
  { label: 'Townhouse', value: 'Townhouse' },
  { label: 'Villa', value: 'Villa' },
  { label: 'Residential Land', value: 'Residential Land' },
  { label: 'Rural Land', value: 'Rural Land' },
];

export const distanceOptions = [
  { label: '5km', value: '5' },
  { label: '4km', value: '4' },
  { label: '3km', value: '3' },
  { label: '2km', value: '2' },
  { label: '1km', value: '1' },
];

export const maxDropDownNumberValue = 20;
export const maxDropDownNumberLabel = '6+';

const numbers = createNumberArray(0, 5);
export const dropDownNumberOptions = [
  { value: null, label: 'Any' },
  ...numbers.map(number => {
    var num = number;

    return {
      value: `${num}`,
      label: `${num}`,
    };
  }),
  { value: maxDropDownNumberValue, label: maxDropDownNumberLabel },
];
