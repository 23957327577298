import {
  Images,
  AgxBodyText,
  AgxColumn,
  AgxColours,
} from '@urbanx/agx-ui-components';
import {
  EnvelopeSubmissionStatus,
  EnvelopeSubmissionType,
} from 'Api/Campaigns/Types/types';
import './SigningStatus.scss';

interface SigningStatusProps {
  status?: EnvelopeSubmissionStatus;
  type?: EnvelopeSubmissionType | null;
}

const SigningStatus = ({ status, type }: SigningStatusProps) => {
  const getStatusInformation = () => {
    switch (status) {
      case EnvelopeSubmissionStatus.Created:
        return (
          <>
            <Images.PaperPlaneOutline />
            <AgxBodyText extraClasses={AgxColours.NEUTRAL_GREY_700}>
              Sending...
            </AgxBodyText>
          </>
        );
      case EnvelopeSubmissionStatus.Sent:
      case EnvelopeSubmissionStatus.Opened:
        return (
          <>
            {type === EnvelopeSubmissionType.DocuSign ? (
              <Images.Clock />
            ) : (
              <Images.PaperPlaneOutline />
            )}
            <AgxBodyText extraClasses={AgxColours.NEUTRAL_GREY_700}>
              {type === EnvelopeSubmissionType.DocuSign
                ? 'Awaiting signatures'
                : 'Sent'}
            </AgxBodyText>
          </>
        );
      case EnvelopeSubmissionStatus.Signed:
      case EnvelopeSubmissionStatus.Complete:
        return (
          <>
            <Images.Checkmark />
            <AgxBodyText extraClasses={AgxColours.NEUTRAL_GREY_700}>
              {type === EnvelopeSubmissionType.DocuSign ? 'Signed' : 'Sent'}
            </AgxBodyText>
          </>
        );
      case EnvelopeSubmissionStatus.Declined:
        return (
          <>
            <div style={{ width: '32px', height: '32px' }}>
              <Images.MinusCircle />
            </div>
            <AgxBodyText extraClasses={AgxColours.NEUTRAL_GREY_700}>
              Declined
            </AgxBodyText>
          </>
        );
      case EnvelopeSubmissionStatus.Bounced:
        return (
          <>
            <div style={{ width: '32px', height: '32px' }}>
              <Images.AlertTriangle />
            </div>
            <AgxBodyText extraClasses={AgxColours.NEUTRAL_GREY_700}>
              Email Bounced
            </AgxBodyText>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <AgxColumn centered smallGap extraClasses="signingStatus">
      {getStatusInformation()}
    </AgxColumn>
  );
};

export default SigningStatus;
