import React from 'react';
import {
  AgxRow,
  AgxButton,
  Images,
  AgxColumn,
  DocumentTypes,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { CampaignDetailModel } from 'Api/Campaigns/Types/campaignTypes';
import { requestGetFileLink } from 'components/ui-components/File/fileApi';
import { useAzureAuth } from 'hooks/useAzureAuth';
import placeHolderImage from 'assets/images/placeholder-property.png';
import './ListingDetails.scss';

interface ListingDetailsProps {
  mobile?: boolean;
  campaign: CampaignDetailModel;
}

const ListingDetails: React.FC<ListingDetailsProps> = ({
  mobile = false,
  campaign,
}) => {
  const [, getAuthToken] = useAzureAuth();

  const { propertyImageUrls, documents = [], completionState } = campaign;

  const imageUrl = propertyImageUrls?.medium ?? '';
  const availableForms = useAppSelector(state => state.forms.availableForms);
  const form = availableForms.find(form => form.beginsCampaign) ?? null;

  const getAgencyAgreementLink = async () => {
    const agencyAgreementCampaign = documents.find(
      d => d.documentType === DocumentTypes.AgencyAgreement
    );

    if (!agencyAgreementCampaign) {
      return null;
    }

    const authToken = await getAuthToken();

    if (!authToken) return null;

    const link = await requestGetFileLink(
      authToken,
      agencyAgreementCampaign.containerFilePath
    );

    return link;
  };

  const onDownloadAgencyAgreementClick = async () => {
    const link = await getAgencyAgreementLink();
    if (link !== null) {
      // window.open does not work with latest safari browser
      // Workaround: wrap the window.open call to settimeout.
      // setTimeout code runs on the main thread, instead of the asynchronous one.
      setTimeout(() => {
        window.open(link, '_blank');
      });
    }
  };

  return (
    <AgxColumn fill centered largeGap extraClasses="contractListingDetails">
      <AgxRow fill centered justifyCenter extraClasses={'listing-imageCard'}>
        <div
          style={{
            backgroundImage: `url("${imageUrl || placeHolderImage}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            aspectRatio: '2/1',
          }}
          className={`imageCard-image${completionState.completionType ? ' archived' : ''}`}
        ></div>
      </AgxRow>

      <AgxButton
        hollow
        text={`View ${form?.name}`}
        rightIcon={<Images.FileTextOutline />}
        medium
        wide={mobile}
        onClick={async () => await onDownloadAgencyAgreementClick()}
        shrinkOnLargerDevices
      />
    </AgxColumn>
  );
};

export default ListingDetails;
