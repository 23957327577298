import { AgxBodyText, AgxRow, EntityImage } from '@urbanx/agx-ui-components';
import clsx from 'clsx';
import { LeaderBoardPositionModel } from 'types/Reporting';

interface SidebarLeaderboardRowProps {
  leaderboardPosition: LeaderBoardPositionModel;
  addRingToImage: boolean;
}

const SidebarLeaderboardRow = ({
  leaderboardPosition,
  addRingToImage,
}: SidebarLeaderboardRowProps) => {
  const {
    rank,
    name,
    photoUri,
    backgroundColour,
    backgroundTextColour,
    isPersonalEntity,
  } = leaderboardPosition;

  const classes = clsx('leaderboard-row', isPersonalEntity && 'highlighted');

  return (
    <AgxRow fill centered mediumGap extraClasses={classes}>
      <AgxBodyText small extraClasses="leaderboard-rowText rank">
        {rank + 1}
      </AgxBodyText>
      <AgxRow centered fill extraClasses="image-agent">
        <EntityImage
          photoUri={photoUri}
          backgroundColour={backgroundColour}
          backgroundTextColour={backgroundTextColour}
          name={name}
          addRingToImage={addRingToImage}
        />
        <AgxBodyText small extraClasses="leaderboard-rowText name">
          {name}
        </AgxBodyText>
      </AgxRow>
    </AgxRow>
  );
};

export default SidebarLeaderboardRow;
