import React, { useState } from 'react';
import {
  AgxColumn,
  AgxCheckbox,
  AgxPriceRange,
} from '@urbanx/agx-ui-components';

const toBeConfirmedLabel = 'To be confirmed';

const PriceSpecification = props => {
  const {
    id,
    label,
    subDescription,
    onValueChanged,
    defaultValue = null,
    showToBeConfirmed = true,
    maxPriceRangePercent = null,
    defaultUpperRangeSelected = false,
    required,
  } = props;

  const [toBeConfirmed, setToBeConfirmed] = useState(
    defaultValue?.toBeConfirmed ?? false
  );

  return (
    <AgxColumn fill largeGap>
      <AgxColumn fill mediumGap>
        <AgxPriceRange
          id={id}
          label={label}
          subDescription={subDescription}
          disabled={toBeConfirmed}
          defaultValue={defaultValue?.priceRange}
          disabledText={toBeConfirmedLabel}
          onValueChanged={change => {
            onValueChanged({
              id,
              value: {
                ...defaultValue,
                priceRange: change,
              },
            });
          }}
          maxPriceRangePercent={maxPriceRangePercent}
          defaultUpperRangeSelected={defaultUpperRangeSelected}
          required={required}
        />
        {showToBeConfirmed && (
          <AgxCheckbox
            id={`${id}Chkbox`}
            onValueChanged={({ value }) => {
              setToBeConfirmed(value);
              onValueChanged({
                id,
                value: {
                  ...defaultValue,
                  toBeConfirmed: value,
                },
              });
            }}
            label={`"${toBeConfirmedLabel}"`}
            stretch
            naked
            defaultValue={toBeConfirmed}
          />
        )}
      </AgxColumn>
    </AgxColumn>
  );
};

export default PriceSpecification;
