import { useCallback, useState, useRef } from 'react';
import axios from 'axios';
import { throttle } from 'lodash';
import { useAppSelector } from './useAppSelector';
import { useAzureAuth } from './useAzureAuth';
import { fetchSuburbSuggestions } from 'Api/RpData/rpDataApi';
import { SuburbSuggestions } from '@urbanx/agx-ui-components/dist/cjs/types/components/portal/PropertyAddressForm/SuburbAutoComplete/SuburbAutoComplete';

const useSuburbSuggestions = () => {
  const [loading, setLoading] = useState(false);
  const [suburbSuggestions, setSuburbSuggestions] = useState<
    SuburbSuggestions[]
  >([]);
  const ongoingRequests = useRef<AbortController[]>([]);
  const [, getAuthToken] = useAzureAuth();
  const selectedForm = useAppSelector(state => state.form.selectedForm);
  const getSuburbSuggestions = useCallback(
    throttle(async (searchQuery: string) => {
      if (!searchQuery || searchQuery.length < 3) {
        setSuburbSuggestions([]);
        return;
      }

      if (!selectedForm) return;

      setLoading(true);

      const controller = new AbortController();
      try {
        ongoingRequests.current.forEach((controller: AbortController) =>
          controller.abort()
        );
        ongoingRequests.current = [];

        ongoingRequests.current.push(controller);

        const authToken = await getAuthToken();
        if (!authToken) return;

        const result = await fetchSuburbSuggestions(
          authToken,
          searchQuery,
          selectedForm.state,
          controller.signal
        );

        setSuburbSuggestions(result?.suburbSuggestions || []);
      } catch (error) {
        if (!controller.signal.aborted && !axios.isCancel(error)) {
          console.error(error);
        }
        setSuburbSuggestions([]);
      } finally {
        setLoading(false);
      }
    }, 100),
    []
  );

  return { suburbSuggestions, loading, getSuburbSuggestions };
};

export default useSuburbSuggestions;
