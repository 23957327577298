import {
  FormType,
  UploadedDocument,
  StakeholderType,
  AustralianState,
} from '@urbanx/agx-ui-components';

export enum EnvelopeSubmissionType {
  Manual = 'Manual',
  DocuSign = 'DocuSign',
  Email = 'Email',
}

export enum EnvelopeSubmissionStatus {
  Created = 'Created',
  Sent = 'Sent',
  Opened = 'Opened',
  Signed = 'Signed',
  Complete = 'Complete',
  Declined = 'Declined',
  Bounced = 'Bounced',
}

export interface SendFormSubmission {
  campaignId: string;
  formId: string;
  formType: FormType;
  state: AustralianState;
  digitalSignatureSubmission: DigitalSignatureSubmission;
}

export interface DigitalSignatureSubmission {
  vendorDetails?: DigitalSignatureClientDetails;
  buyerDetails?: DigitalSignatureClientDetails;
  recipientDetails?: DigitalSignatureClientDetails;
  agentId?: string;
  agentName?: string;
  carbonCopies?: string[];
  sendVendorsNow: boolean;
  resendEnvelope?: boolean;
}

export interface DigitalSignatureClientDetails {
  emailOverrides: { [key: string]: string } | null;
  subject?: string;
  body?: string;
  name?: string;
  preferredName?: string;
  stakeholderType?: StakeholderType | null;
}

export interface GeneratedDocumentModel {
  formId: string;
  document: UploadedDocument;
}

export interface EnvelopeSubmission {
  formDocumentSubmissionId: string;
  contractId: string | null;
  type: EnvelopeSubmissionType | null;
  status: EnvelopeSubmissionStatus;
  recipients: EnvelopeSubmissionRecipient[];
  createdAt: Date;
  updatedAt: Date | null;
}

export interface EnvelopeSubmissionRecipient {
  recipientId: string;
  emailAddress: string;
  name: string;
  preferredName: string;
  sentDateTime: Date | null;
  deliveredDateTime: Date | null;
  signedDateTime: Date | null;
  status: EnvelopeSubmissionStatus;
  stakeholderType: StakeholderType | null;
}

export interface InProgressFormModel {
  formId: string;
  formType: FormType;
  state: AustralianState;
  lastUpdate: Date;
}

export interface CampaignUpdatedEvent {
  campaignId: string;
}
