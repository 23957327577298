import { AgxLabel } from '@urbanx/agx-ui-components';
import { CampaignsViewPageImageCard } from '../../../CampaignsViewPageImageCard/CampaignsViewPageImageCard';
import { CampaignDetailModel } from 'Api/Campaigns/Types/campaignTypes';

interface GroupedCampaignProps {
  title?: string;
  campaigns: CampaignDetailModel[];
  onCampaignClick: (campaignId: string) => void;
  unread?: boolean;
}

export const GroupedCampaigns = ({
  title,
  campaigns,
  onCampaignClick,
  unread = false,
}: GroupedCampaignProps) => {
  if (campaigns.length === 0) return null;

  return (
    <>
      {title && <AgxLabel small>{title}</AgxLabel>}
      {campaigns.map(campaign => (
        <CampaignsViewPageImageCard
          key={campaign.campaignId}
          onClick={() => onCampaignClick(campaign.campaignId)}
          campaign={campaign}
          unread={unread}
        />
      ))}
    </>
  );
};
