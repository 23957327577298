import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AgxCheckbox,
  AgxColumn,
  AgxCurrency,
  AgxTextInput,
} from '@urbanx/agx-ui-components';
import { SaleType } from '../SaleDetails/SaleDetails';
import { RuralLandSize } from 'constants/RuralConstants';
import './DisplayPrice.scss';
import useFormPropertyDetailsSelector from 'selectors/useFormPropertyDetailsSelector';

const toBeConfirmedLabel = 'To be confirmed';

const DisplayPrice = ({
  id,
  label,
  subLabel,
  nonRuralAgreementSubLabel,
  subDescription,
  onValueChanged,
  defaultValue = null,
  showToBeConfirmed = false,
  showReservePrice = false,
}) => {
  const propertyDetails = useFormPropertyDetailsSelector();

  const saleDetails = useSelector(state => state.form?.formValues?.SaleDetails);

  const isRural = propertyDetails?.totalLandArea > RuralLandSize;

  const [toBeConfirmed, setToBeConfirmed] = useState(
    defaultValue?.toBeConfirmed ?? false
  );
  const [displayPrice, setDisplayPrice] = useState(defaultValue?.price ?? '');

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        toBeConfirmed,
        price: displayPrice,
      },
    });
  }, [toBeConfirmed, displayPrice]);

  useEffect(() => {
    if (
      !toBeConfirmed &&
      saleDetails?.saleType === SaleType.Auction &&
      Number.isNaN(Number(displayPrice))
    ) {
      setDisplayPrice('');
    }
  }, [saleDetails]);

  const onTextChanged = ({ value }) => {
    if (!toBeConfirmed) {
      setDisplayPrice(value);
    }
  };

  const onCheckboxChanged = ({ value }) => {
    setToBeConfirmed(value);
  };

  return (
    <AgxColumn fill largeGap>
      <AgxColumn fill mediumGap>
        {!toBeConfirmed &&
        saleDetails?.saleType === SaleType.Auction &&
        showReservePrice ? (
          <AgxCurrency
            id={id}
            label="Reserve Price"
            onInputValueChange={onTextChanged}
            stretch
            disabled={toBeConfirmed}
            required
            defaultValue={toBeConfirmed ? toBeConfirmedLabel : displayPrice}
            subDescription={subDescription}
          />
        ) : (
          <AgxTextInput
            id={id}
            label={
              (isRural &&
                saleDetails?.saleType === SaleType.PrivateTreaty &&
                label) ||
              (isRural &&
                saleDetails?.saleType === SaleType.Auction &&
                'Reserve Price') ||
              (!isRural &&
                saleDetails?.saleType === SaleType.PrivateTreaty &&
                label) ||
              (!isRural &&
                toBeConfirmed &&
                saleDetails?.saleType === SaleType.Auction &&
                'Reserve Price') ||
              (!isRural &&
                saleDetails?.saleType === SaleType.Auction &&
                'Price Guide') ||
              label
            }
            onInputValueChange={onTextChanged}
            stretch
            disabled={toBeConfirmed}
            noOptionalLabel
            required
            defaultValue={toBeConfirmed ? toBeConfirmedLabel : displayPrice}
            subDescription={subDescription}
            subLabel={!isRural ? nonRuralAgreementSubLabel : subLabel}
            parentControlValue
          />
        )}
        {showToBeConfirmed && (
          <AgxCheckbox
            id={`${id}Chkbox`}
            onValueChanged={onCheckboxChanged}
            label={`"${toBeConfirmedLabel}"`}
            stretch
            naked
            defaultValue={toBeConfirmed}
          />
        )}
      </AgxColumn>
    </AgxColumn>
  );
};

export default DisplayPrice;
