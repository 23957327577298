import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  AgxRow,
  AgxLabel,
  AgxRadio,
  AgxTextInput,
  AgxDatePicker,
  Images,
  AgxBodyText,
  AgxRadioGroup,
  AgxSelect,
  NoticeTimeUnit,
  Breakpoints,
  useWindowSize,
} from '@urbanx/agx-ui-components';
import SpecialClause from '../SpecialClause/SpecialClause';
import './SettlementDate.scss';
import {
  SettlementConditionType,
  SettlementInLineWithOptions,
  TimeOptions,
} from './types';

interface SettlementDateProps {
  id: string;
  label: string;
  onValueChanged: (value: any) => void;
  defaultValue: any;
  validate?: boolean;
  required?: boolean;
  showSpecialConditionOption?: boolean;
  showAsPerContractOption?: boolean;
  showInLineWithOption?: boolean;
  showFromContractDateDropdownOption?: boolean;
}

const SettlementDate: React.FC<SettlementDateProps> = ({
  id,
  label,
  onValueChanged,
  defaultValue,
  validate = false,
  required = false,
  showSpecialConditionOption,
  showAsPerContractOption,
  showInLineWithOption,
  showFromContractDateDropdownOption,
}) => {
  const inLineWithText = 'In line with ';
  const [settlementDate, setSettlementDate] = useState({
    condition: defaultValue?.condition ?? SettlementConditionType.AsPerContract,
    daysFromDate: defaultValue?.daysFromDate,
    fromDateTimeUnit: defaultValue?.fromDateTimeUnit ?? NoticeTimeUnit.Days,
    referenceDate: defaultValue?.referenceDate,
    customSpecialCondition: defaultValue?.customSpecialCondition,
    other: defaultValue?.other?.replace(inLineWithText, ''),
  });
  const windowSize = useWindowSize();

  const {
    condition,
    daysFromDate,
    referenceDate,
    customSpecialCondition,
    fromDateTimeUnit,
    other,
  } = settlementDate;

  useEffect(() => {
    onValueChanged({ id, value: settlementDate });
  }, [settlementDate]);

  const settlementDateClasses = clsx(
    'settlementDateTextInput',
    validate && required && !settlementDate.daysFromDate && 'error'
  );

  const getFromContractDateSubContent = () => {
    if (showFromContractDateDropdownOption) {
      return [
        <>
          <AgxRow centered mediumGap key="settlementTextInput">
            <AgxTextInput
              id="numberOfDays"
              noHeader
              numberOnly
              noOptionalLabel
              small
              maxLength={3}
              defaultValue={daysFromDate}
              extraClasses={settlementDateClasses}
              onInputValueChange={({ value }) =>
                setSettlementDate({
                  ...settlementDate,
                  daysFromDate: value,
                })
              }
            />
            <AgxSelect
              id="timeUnit"
              options={TimeOptions}
              onValueChanged={({ value }) => {
                setSettlementDate({
                  ...settlementDate,
                  fromDateTimeUnit: value,
                });
              }}
              defaultValue={TimeOptions.find(t => t.value === fromDateTimeUnit)}
              required
              noHeader
              isMini
            />
            {windowSize !== Breakpoints.Mobile && (
              <AgxLabel small extraClasses="fromContractDateLabel">
                {'from Contract date'}
              </AgxLabel>
            )}
          </AgxRow>
          {windowSize === Breakpoints.Mobile && (
            <AgxRow>
              <AgxLabel small extraClasses="fromContractDateLabel">
                {'from Contract date'}
              </AgxLabel>
            </AgxRow>
          )}
          <AgxRow>
            {validate && required && !settlementDate.daysFromDate && (
              <div className={`errorMessage`}>
                <Images.AlertCircle />
                <AgxBodyText small extraClasses={'error'}>
                  {`Enter ${fromDateTimeUnit && fromDateTimeUnit === NoticeTimeUnit.Weeks.toString() ? 'weeks' : 'days'}`}
                </AgxBodyText>
              </div>
            )}
          </AgxRow>
        </>,
      ];
    } else {
      return [
        <AgxRow centered mediumGap key="settlementTextInput">
          <AgxTextInput
            id="numberOfDays"
            noHeader
            numberOnly
            noOptionalLabel
            small
            maxLength={3}
            defaultValue={daysFromDate}
            extraClasses="settlementDateTextInput"
            onInputValueChange={({ value }) =>
              setSettlementDate({
                ...settlementDate,
                daysFromDate: value,
              })
            }
            rightIcon={<AgxLabel small>days</AgxLabel>}
            error={
              validate && !settlementDate.daysFromDate
                ? 'Enter days'
                : undefined
            }
          />
          <AgxLabel small>from Contract date</AgxLabel>
        </AgxRow>,
      ];
    }
  };

  return (
    <div className="agxRadio">
      <AgxLabel medium>{label}</AgxLabel>
      <div className="agxRadio-group">
        {showAsPerContractOption && (
          <AgxRadio
            id="settlementDateAsPerContract"
            label="As per Contract"
            onCheckChanged={() =>
              setSettlementDate({
                ...settlementDate,
                condition: SettlementConditionType.AsPerContract,
              })
            }
            checked={condition === SettlementConditionType.AsPerContract}
          />
        )}
        <AgxRadio
          id="settlementDateDays"
          label="__ from Contract date"
          onCheckChanged={() =>
            setSettlementDate({
              ...settlementDate,
              condition: SettlementConditionType.NdaysFromDate,
            })
          }
          checked={condition === SettlementConditionType.NdaysFromDate}
          subContent={
            condition === SettlementConditionType.NdaysFromDate
              ? getFromContractDateSubContent()
              : undefined
          }
        />
        <AgxRadio
          id="settlementDateDate"
          label="On specific date"
          onCheckChanged={() =>
            setSettlementDate({
              ...settlementDate,
              condition: SettlementConditionType.OnSpecificDate,
            })
          }
          checked={condition === SettlementConditionType.OnSpecificDate}
          subContent={
            condition === SettlementConditionType.OnSpecificDate
              ? [
                  <AgxDatePicker
                    key="settlementDateOnSpecificDate"
                    id="settlementDateOnSpecificDate"
                    onValueChanged={({ value }) =>
                      setSettlementDate({
                        ...settlementDate,
                        referenceDate: value,
                      })
                    }
                    required
                    noHeader
                    noOptionalLabel
                    defaultValue={referenceDate}
                    date
                    disablePastDates={true}
                    stretch
                    error={
                      validate && referenceDate === ''
                        ? 'Enter a date'
                        : undefined
                    }
                  />,
                ]
              : undefined
          }
        />
        {showSpecialConditionOption && (
          <AgxRadio
            id="settlementDateSpecialCondition"
            label="Refer to Special Condition"
            onCheckChanged={() =>
              setSettlementDate({
                ...settlementDate,
                condition:
                  SettlementConditionType.UponSatisfactionOfSpecialCondition,
              })
            }
            checked={
              condition ===
              SettlementConditionType.UponSatisfactionOfSpecialCondition
            }
            subContent={
              condition ===
              SettlementConditionType.UponSatisfactionOfSpecialCondition
                ? [
                    <SpecialClause
                      key="settlementDateOnSpecialClause"
                      id="settlementDateOnSpecialClause"
                      defaultValue={customSpecialCondition}
                      validate={validate}
                      onSelectedClause={value =>
                        setSettlementDate({
                          ...settlementDate,
                          customSpecialCondition: value,
                        })
                      }
                    />,
                  ]
                : undefined
            }
          />
        )}
        {showInLineWithOption && (
          <AgxRadio
            id="settlementDateInLineWith"
            label={inLineWithText}
            onCheckChanged={() =>
              setSettlementDate({
                ...settlementDate,
                condition: SettlementConditionType.Other,
              })
            }
            checked={condition === SettlementConditionType.Other}
            subContent={
              condition === SettlementConditionType.Other
                ? [
                    <div className="agx-radio-group">
                      <AgxRadioGroup
                        key={'inLineWithOptions'}
                        label=""
                        id={'inLineWithOptions'}
                        options={SettlementInLineWithOptions}
                        onValueChanged={(selectedOption: any) =>
                          setSettlementDate({
                            ...settlementDate,
                            other: `${inLineWithText}${selectedOption.value}`,
                          })
                        }
                        defaultValue={other}
                        validate={validate}
                        required={required}
                      />
                    </div>,
                  ]
                : undefined
            }
          />
        )}
      </div>
      {validate && required && !settlementDate.condition && (
        <div className="errorMessage">
          <Images.AlertCircle />
          <AgxBodyText small extraClasses="error">
            {'Select an option'}
          </AgxBodyText>
        </div>
      )}
    </div>
  );
};

export default SettlementDate;
